import React, { useState } from "react";
import "./ContactUs.css";

const sendEmail = async (config) => {
  if (window.Email && typeof window.Email.send === "function") {
    try {
      await window.Email.send(config);
    } catch (error) {
      console.error(error);
    }
  }
};

const ContactUs = () => {
  const [formState, setFormState] = useState({});

  const changeHandler = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const submitHandler = (event) => {
    const config = {
      SecureToken: "e60d985c-6c67-4894-af28-de66d0ba1897",
      To: "info@jadegardenmyanmar.com",
      From: "client-sender@jadegardenmyanmar.com",
      Subject: "This email from your client.",
      Address: formState.address,
      Body: `${formState.message} - Your Name: ${formState.name},   Company Name: ${formState.companyName},   Address: ${formState.address},   Ph: ${formState.number},   Email: ${formState.email}`,
    };
    sendEmail(config).then(() => alert("Email sent successfully"));
    event.preventDefault();
    document.getElementById('text-box').value = "";
    document.getElementById('company-box').value = "";
    document.getElementById('email-box').value = "";
    document.getElementById('number-box').value = "";
    document.getElementById('address-box').value = "";
    document.getElementById('message-box').value = "";
  };

  const iconColor = ["var(--primary)"];

  return (
    <>
      <div className="contactContainer">
        <div className="infoContact">
          <h2>Contact Us</h2>
          <h1>Get In Touch</h1>
          <div className="address">
            <div className="address-icon">
              <i
                class="fas fa-map-location-dot"
                style={{ color: iconColor }}
              ></i>
            </div>
            <div className="address-Text">
              <h3>Office Location</h3>
              <p>
                No 47/30, Ye Mon Taung Quater, Chan Aye Thar Zan Township,
                Mandalay Region, Myanmar
              </p>
            </div>
          </div>
          <div className="address">
            <div className="address-icon">
              <i class="fas fa-phone" style={{ color: iconColor }}></i>
            </div>
            <div className="address-Text">
              <h3>Telephone Number</h3>
              <p>+959-404099553</p>
            </div>
          </div>
          <div className="address">
            <div className="address-icon">
              <i
                class="fas fa-envelope-open-text"
                style={{ color: iconColor }}
              ></i>
            </div>
            <div className="address-Text">
              <h3>Email Address</h3>
              <p>info@jadegardenmyanmar.com</p>
            </div>
          </div>
        </div>
        <div className="contact">
          <form className="contactForm" onSubmit={submitHandler}>
            <div className="formLine">
              <div className="formRow">
                <div className="labelRow1">
                  <label>
                    Your Name<span>*</span>
                  </label>
                </div>
                <div className="inputRow1">
                  <input
                    className="contactInput"
                    id="text-box"
                    type="text"
                    name="name"
                    placeholder="Your Name"
                    value={formState.name || ""}
                    onChange={changeHandler}
                    required="reqiureed"
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="labelRow1">
                  <label>
                    Company Name<span>*</span>
                  </label>
                </div>
                <div className="inputRow1">
                  <input
                    className="contactInput"
                    id="company-box"
                    type="text"
                    name="companyName"
                    placeholder="Company Name"
                    value={formState.companyName || ""}
                    onChange={changeHandler}
                    required="reqiureed"
                  />
                </div>
              </div>
            </div>
            <div className="formLine">
              <div className="formRow">
                <div className="labelRow1">
                  <label>
                    Email<span>*</span>
                  </label>
                </div>
                <div className="inputRow1">
                  <input
                    className="contactInput"
                    id="email-box"
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formState.email || ""}
                    onChange={changeHandler}
                    required="required"
                  />
                </div>
              </div>
              <div className="formRow">
                <div className="labelRow1">
                  <label>
                    Contact Number<span>*</span>
                  </label>
                </div>
                <div className="inputRow1">
                  <input
                    className="contactInput"
                    id="number-box"
                    type="text"
                    name="number"
                    placeholder="Number"
                    value={formState.number || ""}
                    onChange={changeHandler}
                    required="reqiureed"
                  />
                </div>
              </div>
            </div>
            <div className="formAddress">
              <div className="labelRow1">
                <label>
                  Address<span>*</span>
                </label>
              </div>
              <div className="inputRow1">
                <input
                  className="textInput"
                  id="address-box"
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={formState.address || ""}
                  onChange={changeHandler}
                  required="reqiureed"
                />
              </div>
            </div>
            <div className="formText">
              <div className="labelText">
                <label>
                  Message<span>*</span>
                </label>
              </div>
              <div className="inputText">
                <textarea
                  className="textInput cursor-position"
                  id="message-box"
                  type="text"
                  name="message"
                  placeholder="Message"
                  rows="8"
                  value={formState.message || ""}
                  onChange={changeHandler}
                  required="reqiureed"
                // onChange={(e) => {
                //   setFormState({ ...formState, message: e.target.value });
                // }}
                />
              </div>
            </div>
            <div className="formButton">
              <button onClick={submitHandler}>Send Email</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
