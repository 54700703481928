import React from "react";
import HeroImg from "../assets/images/NewImgs/MainBuilding 1-1.png";
import "./HomeBody.css";


const HomeBody = () => {
  return (
    <div className="container">
      <div className="heroImg">
        <img alt="heroImg" src={HeroImg} />
      </div>
      {/* <div className="backBox"></div> */}
      <div className="homeText">
        <p>
          With the valuable experiences, skills, knowledge, exposure with the
          Jade mining, services about Transport and Logistics supply chain,
          International trading with cross-border proficiency and capability for
          the wide rage of diverse facilities, we put our best effort to
          establish the Jade Garden (JG) at the location of the heart of
          Myanmar, Mandalay
        </p>
        <br />
        <p>
          Jade Garden (JG) project has been intended to create the complete and
          comprehensive one stop service platform and market area for Jade gems
          selling and trading for domestic and international market with online
          and e-commerce support.
        </p>
        <br />
        <p>
          Mandalay is the best location for Jade gems trading market with the
          comparative advantages of better accessibility and trading logistics.
          JG can enhance all the benefits for the Jade lovers around the world.
        </p>
      </div>
    </div>
  );
};

export default HomeBody;
