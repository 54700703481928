import React from "react";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Contact() {
  return (
    <>
      <Navbar />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Contact;
