import React from "react";
import Aboutus from "../components/About-Us";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function AboutUs() {
  return (
    <>
      <Navbar />
      <Aboutus />
      <Footer />
    </>
  );
}

export default AboutUs;
