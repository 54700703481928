import React from "react";
import f_logoImg from "../assets/images/JadeGardenLogoWhite.png";
// import footerImg from "../assets/images/green_background.jpg";
import "../components/Footer.css";

function Footer() {
  return (
    <div className="f-block">
      <div className="a-block">
        <div>
          <div className="f-text">
            <h5>Jade Garden Company Limited</h5>
            <div className="f_address">
              <i class="fas fa-location-dot" />
              <p>
                No.(47/30), Ye Mon Taung Quater, Chan Aye Thar Zan Township,
                Mandalay Region, Myanmar
              </p>
            </div>
            <div className="f-info">
              <div className="f-info1">
                <i class="fas fa-globe" />
                <p>www.jadegardenmyanmar.com</p>
              </div>
            </div>
            <div className="f-info2">
              <div className="f-info1">
                <i class="fas fa-envelope" />
                <p>info@jadegardenmyanmar.com</p>
              </div>
            </div>
            <div className="f-info3">
              <div className="f-info1">
                <i class="fas fa-phone" />
                <p>+95 9 404 099 553</p>
              </div>
            </div>
          </div>
        </div>
        <div className="f-logo">
          <img alt="f-Img" src={f_logoImg} />
          <p>
            Trade today for prosperous tomorrow, Jade Garden is for your goal.
          </p>
        </div>
      </div>
      <div className="f-copy">
        <p>
          Developed by<span>Technovation</span>
        </p>

        <p>
          &copy; Copyright by<span>Jadegardenmyanmar</span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
