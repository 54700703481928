import React from "react";
import serviceImg from "../assets/images/NewImgs/JGWebPhoto4.jpg";
import building1 from "../assets/images/NewImgs/MainBuilding 2-1.png";
import building2 from "../assets/images/NewImgs/MainBuilding 1-1.png";
import blockImg1 from "../assets/images/NewImgs/JGWebPhoto8.jpg";
import blockImg2 from "../assets/images/NewImgs/JGWebPhoto2.jpg";
import blockImg3 from "../assets/images/NewImgs/JGWebPhoto5.jpg";
import blockImg4 from "../assets/images/NewImgs/JGWebPhoto4.jpg";
import block2Img1 from "../assets/images/NewImgs/JGWebPhoto12.jpg";
import block2Img2 from "../assets/images/NewImgs/JGWebPhoto10.jpg";
import block2Img3 from "../assets/images/NewImgs/JGWebPhoto5.jpg";
import "../components/Our-Services.css";

const OurServices = () => {
  return (
    <>
      <div className="serviceContainer">
        <div className="headerImg">
          <img alt="serImg" src={serviceImg} />
        </div>
        {/* <img alt="backImg" src={backImg} className="backImg" /> */}
        <div className="major">
          <div className="plan1">
            <div className="masterPlan">
              <div className="header_plan">
                <h3>Proposed Master Plan</h3>
              </div>
              <div className="masterLayout">
                <div className="building1">
                  <img alt="buildingImg" src={building1} />
                </div>
                <div className="layout">
                  <h3>Master Plan Layout</h3>
                  <p>
                    <h4>Building Development</h4>
                    <ul>
                      <li>JOM Main Building</li>
                      <li>Warehouses(Staff Accomodations)</li>
                      <li>Security Houses</li>
                      <li>Signage</li>
                      <li>Staff Dormitory</li>
                      <li>Service Building(Optional)</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            <div className="block">
              <div>
                <div className="blockBody">
                  <div className="blockHeader">
                    <h4>Jade Online Market(JOM) Main Building</h4>
                  </div>
                  <div className="blockPara">
                    <img alt="blockImg" src={blockImg1} />
                    <p>3-Storeyed Commercial Building</p>
                    <p>
                      Showrooms, display area, meeting rooms, offices, gem
                      trading rooms, online sale area
                    </p>
                    <p>
                      Restaurant, Cafeteria, healthcare room, circulation areas
                      and car & motorbike parking (basement).
                    </p>
                    <p>Total floor area is about 58,275 sq.ft.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="blockBody">
                  <div className="blockHeader">
                    <h4>Jade Warehouse and Factory</h4>
                  </div>
                  <div className="blockPara">
                    <img alt="blockImg" src={blockImg2} />
                    {/* <p>3-Storeyed Commercial Building</p> */}
                    <p>
                      For storage and display of the jade, and sculpturing for
                      jade gems
                    </p>
                    <p>Display areas, work spaces, administration offices</p>
                    <p>Total floor area is about 26,660 sq.ft.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              <div>
                <div className="blockBody">
                  <div className="blockHeader">
                    <h4>Staff Dormitory</h4>
                  </div>
                  <div className="blockPara">
                    <img alt="blockImg" src={blockImg3} />
                    <p>24 Rooms for essential staffs of Jade Garden</p>
                    {/* <p>
                  Showrooms, display area, meeting rooms, offices, gem trading
                  rooms, online sale area
                </p>
                <p>
                  Restaurant, Cafeteria, healthcare room, circulation areas and
                  car & motorbike parking (basement).
                </p> */}
                    <p>Total floor area is about 4,320 sq.ft.</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="blockBody">
                  <div className="blockHeader">
                    <h4>Other Facilities</h4>
                  </div>
                  <div className="blockPara">
                    <img alt="blockImg" src={blockImg4} />
                    <p>Outdoor display area - 44,343 sq.ft. </p>
                    {/* <p>
                  Showrooms, display area, meeting rooms, offices, gem trading
                  rooms, online sale area
                </p>
                <p>
                  Restaurant, Cafeteria, healthcare room, circulation areas and
                  car & motorbike parking (basement).
                </p> */}
                    <p>Outdoor parking area - 24,868 sq.ft.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plan2">
            <div className="masterPlan2">
              <div className="building2">
                <img alt="building2" src={building2} />
              </div>
              <div className="para">
                <h4>Jade Showroom and Year-round Emporium</h4>
                <p>
                  Jade Garden provides the display areas and sale counters for
                  the Gems shops
                </p>
                <p>
                  Private facilitated showroom for all the seasons full
                  furnished trading environment
                </p>
                <p>
                  One stop services for local and international sales for
                  administration purpose.
                </p>
              </div>
            </div>
            <div className="block2">
              <div className="block2para">
                <div className="block2Img">
                  <img alt="block2Img" src={block2Img1} />
                </div>
                <div className="block2Text">
                  <h4>Jade Warehouse and Workshop for Jewellery</h4>
                  <p>
                    Safe and secure place to store the extracted rough gems,
                    sawed, sawed and polished, broken rough gems and pieces of
                    jades
                  </p>
                  <p>
                    Machines and special tools to make finished gems which are
                    improved on rough gems by cutting, polishing, shaping &
                    carving and jade jewellery setting as well
                  </p>
                </div>
              </div>

              <div className="block2para">
                <div className="block2Img">
                  <img alt="block2Img" src={block2Img2} />
                </div>
                <div className="block2Text">
                  <h4>Online Marketplace</h4>
                  <p>
                    Rough gem(jade), finished gem(jade) and jewellery can be
                    sold 24/7 at the online platform of Jade Garden
                  </p>
                  <p>
                    International jade and related trading information from
                    secure sources well be provided
                  </p>
                </div>
              </div>

              <div className="block2para">
                <div className="block2Img">
                  <img alt="block2Img" src={block2Img3} />
                </div>
                <div className="block2Text">
                  <h4>Transport and Logistics Services</h4>
                  <p>
                    Transport and logistics support for the rough gem(jade) and
                    finished gem(jade) to and from of the Gemstone Track Areas,
                    Naypyitaw, Yangon and Border area of China
                  </p>
                  <p>
                    Other related facilities and logistics for the supply chain
                    of the jade selling and trading
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServices;
