import React from "react";
import Footer from "../components/Footer";
import HomeBody from "../components/HomeBody";
import Navbar from "../components/Navbar";

function Home() {
  return (
    <>
      <Navbar />
      <HomeBody />
      <Footer />
    </>
  );
}

export default Home;
