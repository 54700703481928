import React from "react";
import "./About-Us.css";
import aboutImg from "../assets/images/NewImgs/JGWebPhoto9.jpg";
import photo1 from "../assets/images/NewImgs/SaukGyiKaung.png";
import photo2 from "../assets/images/NewImgs/ZayarLynn.png";
import photo3 from "../assets/images/NewImgs/Ko Sai Lon.png";

const Aboutus = () => {
  return (
    <>
      <div className="Abcontainer">
        <div className="aboutImg">
          <img alt="aboutImg" src={aboutImg} />
        </div>
        <div className="human">
          <div className="humanBox">
            <div className="photo">
              <img alt="humanImg" src={photo1} />
            </div>
            <div className="header">
              <h3>Mr. Sauk Gyi Kaung</h3>
              <h5>Chairman</h5>
            </div>
            <div className="textBox">
              <p className="text1-1">
                <li>
                  More than 15 years experience in Jade mining and Jade trading
                  business
                </li>
                <li>
                  More than 7 years experience of the distribution of fuel oil
                  for the better supply chain of the transport (Seven Hwa Jyint
                  Fuel Oil Distribution)
                </li>
              </p>
              <div className="separater">--------"--------</div>
              <p className="text2">
                “I am very glad to have this opportunity to be one of the
                founders of Jade Garden (JG) market place of the country. Ninety
                percent of the world’s jade is mined in Myanmar.” “It has been a
                rewarding and encouraging year for me as the Chairman.” “There
                is no doubt that JG can fulfill the objective of the government
                which is to establish and develop a hundred percent gems and
                jewellery market within Myanmar for gems produced in Myanmar
                especially for JADE.”
              </p>
            </div>
          </div>
          <div className="humanBox">
            <div className="photo">
              <img alt="humanImg" src={photo2} />
            </div>
            <div className="header">
              <h3>Mr. Zay Yar Lin</h3>
              <h5>Director</h5>
            </div>
            <div className="textBox">
              <p className="text1">
                <li>
                  More than 10 years experience of the distribution of fuel oil
                  for the better supply chain of the transport (United Myanmar
                  Energy Co., Ltd. Fuel Oil Distribution)
                </li>
                <li>
                  About 15 years experience of the barge transportation along
                  the Ayeyarwaddy and Chindwin rivers, and near coastal shipping
                  (Core services for the supply chain of the water transport and
                  Logistics. (Ngwe Tagun Co., Ltd.
                </li>
              </p>
              <div className="separater">--------"--------</div>
              <p className="text2">
                “According to the Myanmar Gem Law, we put our great efforts
                together to set up the Jade Garden (JG) to open gem markets
                including online accessibility for selling gems and jewellery
                freely at any time in accordance with the market economic
                system.” “Former experiences of us which are Jade Mining and
                Extraction, Transport and Logistics support to the Jade trading,
                Fuel oil distribution which is the core supporting activity for
                the supply chain of Jade trading, and so on, can enhance and
                improve the optimize services and facilities of JG.”
              </p>
            </div>
          </div>
          <div className="humanBox">
            <div className="photo">
              <img alt="humanImg" src={photo3} />
            </div>
            <div className="header">
              <h3>Mr. Sai Lon</h3>
              <h5>Director</h5>
            </div>
            <div className="textBox">
              <p className="text1-3">
                <li>
                  More than 27 years experience in Jade mining and Jade trading
                  business
                </li>
                {/* <li>
                  More than 7 years experience of the distribution of fuel oil for
                  the better supply chain of the transport (Seven Hwa Jyint Fuel
                  Oil Distribution)
                </li> */}
              </p>
              <div className="separater">--------"--------</div>
              <p className="text2">
                “Myanmar is the largest producer of jade in the world,
                accounting for more than 90% of global supply. ” “Government
                intends to open gem markets for selling gems and jewellery
                freely at any time and to maintain the growth of gem extraction
                and to promote the laboratory work and research in Myanmar Gem
                Industry, Jade Garden (JG) will accomplish the intentions with
                the contributions of our best experiences and exposures
                accordingly .” Sai Lone
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;
