export const Menu = [
  {
    title: "Home",
    url: "/",
    cName: "navbar-links",
    icon: "fa-solid fa-house-chimney",
  },
  {
    title: "About Us",
    url: "/about-us",
    cName: "navbar-links",
    icon: "fa-solid fa-circle-info",
  },
  {
    title: "Our Services",
    url: "/our-services",
    cName: "navbar-links",
    icon: "fa-solid fa-handshake",
  },
  {
    title: "Contact",
    url: "/contact",
    cName: "navbar-links",
    icon: "fa-solid fa-address-book",
  },
];
