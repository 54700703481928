import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import OurServices from "../components/Our-Services";

function Services() {
  return (
    <>
      <Navbar />
      <OurServices />
      <Footer />
    </>
  );
}

export default Services;
